import { template as template_e0dc4081a7dd42109169bbf3769d2d7f } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import icon from "discourse-common/helpers/d-icon";
import { bind } from "discourse-common/utils/decorators";
import { i18n } from "discourse-i18n";
export default class UnreadIndicator extends Component {
    @service
    messageBus;
    constructor(){
        super(...arguments);
        this.messageBus.subscribe(this.unreadIndicatorChannel, this.onMessage);
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this.messageBus.unsubscribe(this.unreadIndicatorChannel, this.onMessage);
    }
    @bind
    onMessage(data) {
        this.args.topic.set("unread_by_group_member", data.show_indicator);
    }
    get unreadIndicatorChannel() {
        return `/private-messages/unread-indicator/${this.args.topic.id}`;
    }
    get isUnread() {
        return typeof this.args.topic.get("unread_by_group_member") !== "undefined";
    }
    static{
        template_e0dc4081a7dd42109169bbf3769d2d7f(`
    {{~#if this.isUnread~}}
      &nbsp;<span
        title={{i18n "topic.unread_indicator"}}
        class="badge badge-notification unread-indicator"
      >
        {{~icon "asterisk"~}}
      </span>
    {{~/if~}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
