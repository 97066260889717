import { template as template_63b8741ce7f346f489589983cd48b54e } from "@ember/template-compiler";
const SidebarSectionMessage = template_63b8741ce7f346f489589983cd48b54e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
