import { template as template_3a29319e238b467db0d2b22539888733 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_3a29319e238b467db0d2b22539888733(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
